@charset "UTF-8";
/******************************************************************************/
/*** LOAD dvGUI DEFAULT-SETTINGS **********************************************/
/******************************************************************************/
/*@import "../../_gui/dvf_gui_assets/scss/default-settings";*/
/******************************************************************************/
/*** OVERWRITE dvGUI DEFAULT-SETTINGS *****************************************/
/******************************************************************************/
/******************************************************************************/
/*** BOOTSTRAP dvGUI SCSS *****************************************************/
/******************************************************************************/
/*@import "../../_gui/dvf_gui_assets/scss/init";*/
* {
  padding: 0;
  margin: 0;
}

body {
  font-family: "Noto Serif", serif;
  font-size: 18px;
}

.article {
  clear: both;
  padding: 0px 1%;
  overflow: auto;
}
.article a {
  text-decoration: underline;
}
.article p {
  margin-bottom: 10px;
}
.article h4 {
  margin-bottom: 5px;
  font-weight: normal;
  font-size: 20px;
}

.subtitle {
  font-weight: bold;
}

.clear {
  clear: both;
}

.news {
  width: 98%;
  padding: 20px 1%;
  max-height: 290px;
}

.description {
  padding: 20px 1%;
}

.img_preview {
  margin: 5px 0px;
  float: left;
  width: 30%;
  height: 180px;
  text-align: center;
}

.img_preview img {
  height: 100%;
  width: 97.5%;
  object-fit: cover;
  margin-right: 2.5%;
}

.art_preview1 {
  padding-left: 32%;
  min-height: 185px;
}

.kachel {
  height: 300px;
  width: 48.5%;
  float: left;
  padding: 20px 0.5% 0 1%;
}
.kachel .image_alt, .kachel .kachelimg {
  width: 100%;
  height: 85%;
  background-color: #BEBEBE;
  cursor: pointer;
}
.kachel .image_alt h4, .kachel .kachelimg h4 {
  padding: 10px;
}
.kachel .image_alt img, .kachel .kachelimg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kachel .displaynone {
  display: none;
}
.kachel .kacheltitel {
  text-align: center;
  height: 15%;
  color: #FFFFFF;
  font-size: 25px;
  background-color: #dd2a57;
  padding-top: 5px;
  font-weight: 600;
}
.kachel a {
  margin-left: 10px;
  padding: 0 5px;
  background-color: #FFFFFF;
  text-decoration: none;
  font-weight: bold;
}

.kachel:hover .image_alt {
  display: block;
}
.kachel:hover .kachelimg {
  display: none;
}

.kachelClear {
  clear: both;
  padding-bottom: 20px;
}

.gallery {
  position: relative;
  height: 400px;
  -webkit-transition: left 0.8s ease-in-out;
  -moz-transition: left 0.8s ease-in-out;
  -o-transition: left 0.8s ease-in-out;
  transition: left 0.8s ease-in-out;
}
.gallery img {
  vertical-align: middle;
  padding-right: 15px;
  height: 85%;
  width: 100%;
  object-fit: cover;
  transition: all 0.6s;
}
.gallery .slides {
  display: inline-flex;
  height: 400px;
}
.gallery .slides .insideSlide {
  overflow: hidden;
  height: 100%;
  width: 97%;
}
.gallery .left {
  position: fixed;
  left: 0;
}

.textContainer {
  height: 15%;
  overflow: hidden;
  transition: all 0.6s;
}

.gallery .insideSlide:hover img {
  opacity: 0.3;
}
.gallery .insideSlide:hover .textContainer {
  margin: -335px 5px 0;
  position: relative;
  height: auto;
}

.biggallerybox .arrow, .gallerybox .arrow {
  border: solid black;
  border-width: 0 3.5px 3.5px 0;
  display: inline-block;
  padding: 3px;
  height: 12px;
  width: 12px;
}
.biggallerybox .right, .gallerybox .right {
  transform: rotate(-45deg);
}
.biggallerybox .left, .gallerybox .left {
  transform: rotate(135deg);
}

.gallerybox {
  padding: 20px 0%;
  margin: 0px 1%;
  overflow-x: hidden;
  position: relative;
  width: 99.5%;
  height: 400px;
}
.gallerybox button {
  cursor: pointer;
  position: absolute;
  top: 42.5%;
  padding: 16px;
  margin-top: -22px;
  color: #000000;
  font-size: 18px;
  border: 0;
  background-color: #FFFFFF;
  opacity: 0.8;
}
.gallerybox .btn_right {
  padding: 12px 16px 12px 8px;
  right: 30px;
}
.gallerybox .btn_left {
  padding: 12px 8px 12px 16px;
  left: 15px;
}

.biggallerybox {
  padding: 0 1%;
}
.biggallerybox .gallery_container {
  height: 600px;
  background-color: rgba(200, 200, 200, 0.5);
}
.biggallerybox .gallery_container .preview_container {
  height: 100%;
  position: relative;
}
.biggallerybox .gallery_container .preview_container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.biggallerybox .gallery_next, .biggallerybox .gallery_previous {
  position: absolute;
  top: 50%;
  display: block;
  height: 50px;
  width: 50px;
  transform: translateY(-50%);
  cursor: pointer;
  padding: 16px;
  color: #000000;
  font-size: 18px;
  border: 0;
  background-color: #FFFFFF;
  opacity: 0.8;
}
.biggallerybox .gallery_next {
  padding: 12px 16px 8px 8px;
  right: 15px;
}
.biggallerybox .gallery_previous {
  padding: 12px 8px 8px 16px;
  left: 15px;
}

.portrait {
  height: 300px;
  width: 31.3%;
  margin: 0.5%;
  float: left;
  padding: 10px 0.5%;
  cursor: pointer;
}
.portrait img {
  height: 75%;
  width: 100%;
  object-fit: cover;
}

.portrait_container {
  width: 98%;
  padding: 20px 1%;
  height: 600px;
}
.portrait_container .portrait_big {
  height: 560px;
  max-height: 560px;
  width: 98%;
  padding: 20px 1%;
  background-size: cover;
  background-position: center;
}
.portrait_container .portrait_big img {
  width: 98%;
  object-fit: cover;
}
.portrait_container .portrait_big .infoField {
  top: -350px;
}
.portrait_container .portrait_big #speechbubble {
  width: 90%;
  margin: 0px auto;
  position: unset;
  transform: unset;
}
.portrait_container .portrait_big .triangle_btm_left {
  border-bottom: 50px solid #dd2a57;
  border-right: 50px solid transparent;
  height: 0;
  margin-left: 10%;
  margin-top: 250px;
  position: unset;
}

.slideshow-container {
  width: 98%;
  height: 600px;
  padding: 20px 1%;
  position: relative;
}
.slideshow-container .slideshow-slide {
  height: 100%;
}
.slideshow-container .slideshow-slide img {
  height: 100%;
  max-height: 600px;
  width: 100%;
  object-fit: cover;
}
.slideshow-container .info_btn {
  position: absolute;
  z-index: 10;
  bottom: 100px;
  left: -50px;
  height: 80px;
}
.slideshow-container .info_btn img {
  height: 100%;
}

.slide-fade-in {
  animation-name: fadeInAndOut;
  animation-duration: 5s;
  transition-timing-function: linear;
}

@keyframes fadeInAndOut {
  0%, 100% {
    opacity: 0;
  }
  20%, 80% {
    opacity: 1;
  }
}
h2 {
  font-family: "Noto Serif", serif;
  font-weight: 700;
  font-size: 30px;
  margin: 15px 0px 15px;
}

h3 {
  font-family: "Noto Serif", serif;
  font-weight: 700;
  font-size: 25px;
  margin: 15px 0px 15px;
}

strong {
  font-family: "Noto Serif", serif;
  font-weight: 700;
}

.download {
  display: block;
  font-family: "Noto Serif", serif;
  text-decoration: underline;
  cursor: pointer;
}

.linkItalic {
  font-family: "Noto Serif", serif;
  text-decoration: underline;
  font-style: italic;
  cursor: pointer;
}

a {
  color: black;
}
a:hover {
  color: #dd2a57;
}

.edit {
  position: relative;
}

.autoHeight {
  max-height: none;
  height: auto;
}

.comment {
  font-weight: normal;
  font-style: italic;
}

h1 {
  padding-left: 1%;
  padding-bottom: 15px;
}

.linklist p {
  font-weight: bold;
  padding-bottom: 20px;
}

.video {
  max-height: 500px;
  height: 50vw;
  width: 100%;
  margin: 20px 0;
}

.banner {
  text-align: center;
  padding: 20px 0px;
  height: 560px;
}
.banner img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.title_images {
  position: relative;
  height: 340px;
  margin-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.title_images img {
  padding-right: 15px;
  height: 80%;
  width: 100%;
  object-fit: cover;
}
.title_images .slides {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.title_images .slides .insideSlide {
  height: 100%;
  width: 97%;
}
.title_images .halfslides {
  height: 100%;
  width: 50%;
}
.title_images .halfslides img {
  height: 100%;
}

#modal {
  height: 100%;
  max-width: 1000px;
  width: 100%;
  margin: 0px auto;
  display: none;
  position: fixed;
  top: 100px;
  background-color: #FFFFFF;
  word-wrap: break-word;
}
#modal #modalimg {
  width: 100%;
}
#modal .modal-content {
  margin: 50px 1%;
  animation-name: zoom;
  animation-duration: 0.6s;
  width: 98%;
  height: 600px;
  background-size: cover;
  background-position: center;
}
@keyframes zoom {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}

.close {
  position: relative;
  margin-left: 95%;
  color: #f1f1f1;
  font-size: 50px;
  font-weight: bold;
  transition: 0.3s;
  cursor: pointer;
}

.triangle_btm_left {
  position: absolute;
  left: 15%;
  top: 380px;
  width: 0;
  height: 0;
  border-bottom: 85px solid #dd2a57;
  border-right: 85px solid transparent;
}

#speechbubble {
  word-wrap: break-word;
  color: white;
  width: 85%;
  background-color: #dd2a57;
  position: absolute;
  top: 500px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 10px;
}
#speechbubble #textspeech {
  font-weight: bold;
  font-size: 25px;
}
#speechbubble #authorspeech {
  margin-top: 10px;
  font-style: italic;
}

.infoField {
  position: relative;
  top: -250px;
}
.infoField #speechbubble {
  margin: 0 auto;
  position: unset;
  transform: none;
}
.infoField .triangle_btm_left {
  position: unset;
  margin-left: 125px;
}

.resultValue {
  max-height: 200px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}

.result {
  border: solid 1.5px #FFFFFF;
  padding: 10px 1%;
}

.result:hover {
  border: solid 1.5px #000000;
  transition: border 1s;
}

.noContent {
  padding: 20px 1%;
}

li {
  padding: 5px 0px;
  margin-left: 20px;
}

.articleText {
  overflow: hidden;
}

.btnContainer {
  padding: 10px 0px;
}

.openArticle {
  max-height: unset;
  overflow: unset;
}

#docDisplay {
  width: 98%;
  height: 700px;
  max-height: calc(90vh - 120px);
  padding: 15px 1% 10px;
}

#loginInput {
  padding-top: 270px;
  width: 100%;
  min-width: 350px;
  max-width: 600px;
  margin: 0px auto;
  width: 94%;
}

#pwInput {
  font-size: 18px;
  border: none;
  padding: 0px 10px;
  width: calc(70% - 20px);
  height: 50px;
  margin-right: 2%;
}

#loginBtn {
  font-size: 18px;
  width: 28%;
  height: 50px;
  border: none;
  background-color: #dd2a57;
  color: #FFFFFF;
  cursor: pointer;
  font-weight: bold;
}

.form {
  padding: 20px 0px;
}

.formContainer {
  height: 600px;
  background-color: #bebebe;
  width: 98%;
  margin: 0px 1%;
}

.network {
  padding: 10px 0px;
}
.network .networktext {
  margin-top: 10px;
}

.toggleContainer {
  cursor: pointer;
  padding: 0px 10px;
  background-color: #bebebe;
}
.toggleContainer p {
  margin: 0;
  padding: 5px 0px;
}

.toggleContainer:hover {
  background-color: #dd2a57;
  color: #FFFFFF;
  transition: all 0.6s ease;
}

.displayNone {
  display: none;
}

.first-title {
  padding-top: 15px;
}

@media screen and (max-width: 1200px) {
  #modal {
    margin-right: 100px;
    width: calc(100% - 200px);
  }
  #modal .modal-content {
    width: 98%;
    height: 50vw;
  }
  #modal .modal-content .triangle_btm_left {
    left: 10%;
    top: calc(50vw + 7%);
  }
  #modal .modal-content #speechbubble {
    margin-top: calc(50vw + 10% + 100px);
    top: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .news {
    height: auto;
    max-height: unset;
  }
  .news .img_preview {
    float: none;
    width: 100%;
  }
  .news .img_preview img {
    width: 100%;
  }
  .news .art_preview1 {
    padding-left: unset;
    min-height: unset;
  }

  .portrait_container {
    height: auto;
    margin-bottom: -100px;
  }
  .portrait_container .portrait_big {
    height: 360px;
  }
  .portrait_container .infoField {
    /*
    position: absolute;
    top: calc(50vw + 30px);
    max-width: calc(100% - 50px);
    */
    margin: 0 20px;
    top: -100px;
  }
  .portrait_container .infoField .triangle_btm_left {
    margin-left: 10vw;
    border-bottom: 50px solid #dd2a57;
    border-right: 50px solid transparent;
  }

  .banner {
    height: 360px;
  }

  #modal {
    width: calc(100% - 100px);
  }
  #modal .infoField {
    top: -30vw;
    margin: 0 20px;
  }
  #modal .infoField .triangle_btm_left {
    margin-left: 10vw;
    border-bottom: 50px solid #dd2a57;
    border-right: 50px solid transparent;
  }

  .slideshow-container {
    height: 400px;
  }

  .gallerybox {
    height: 340px;
  }
  .gallerybox .gallery {
    height: 340px;
  }
  .gallerybox .slides {
    height: 340px;
  }
  .gallerybox img {
    height: 100%;
  }
  .gallerybox .btn_right, .gallerybox .btn_left {
    top: 48.5%;
  }

  .textContainer {
    display: none;
  }
}
@media screen and (max-width: 800px) {
  .kachel {
    width: 98%;
    padding-bottom: 15px;
  }

  .kachelClear {
    padding-bottom: 0px;
  }

  .portrait {
    width: 48%;
  }

  .gallerybox .button {
    top: 50%;
  }

  .gallery img {
    height: 100%;
  }

  .comment {
    display: none;
  }

  .title_images .slides {
    width: 100%;
  }

  #halfslide2 {
    display: none;
  }

  .close {
    margin-left: 92%;
  }

  .banner {
    height: 280px;
  }
}
@media screen and (max-width: 500px) {
  .portrait {
    height: auto;
    width: 97%;
    padding-bottom: 20px;
  }

  #modal {
    width: calc(100% - 40px);
  }
  #modal .modal-content {
    margin: 50px 2%;
    width: 96%;
  }

  #loginInput {
    min-width: unset;
    padding-top: 140.5px;
  }

  .formContainer {
    height: 400px;
    width: 96%;
    margin: 0px 2%;
  }

  #pwInput, #loginBtn {
    display: block;
    width: 98%;
    margin: 0px 1% 25px;
  }

  #pwInput {
    width: calc(98% - 20px);
  }

  .close {
    margin-left: 87%;
  }

  #speechbubble #textspeech {
    font-size: 22px;
  }

  .banner {
    height: 220px;
  }
}
#menucontainer {
  display: none;
  max-width: 1000px;
  margin: 0px 100px;
  height: 40px;
}

#menubutton {
  display: none;
  position: absolute;
  right: 0%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 999;
  width: 40px;
  height: 40px;
  top: 86px;
  background-color: none;
  margin-right: 110px;
}

#menubutton span {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, top 0.25s ease-in-out;
  display: block;
  position: absolute;
  height: 7px;
  width: 40px;
  background: black;
  opacity: 1;
}

#menubutton.open span:nth-child(1), #menubutton.open span:nth-child(4) {
  width: 0px;
  top: 18px;
}

#menubutton.open span:nth-child(2) {
  transform: rotate(45deg);
  width: 50px;
  left: -5px;
}

#menubutton.open span:nth-child(3) {
  transform: rotate(-45deg);
  width: 50px;
  left: -5px;
}

#menubutton span:nth-child(1) {
  top: 10px;
}

#menubutton span:nth-child(2), #menubutton span:nth-child(3) {
  top: 22px;
}

#menubutton span:nth-child(4) {
  top: 34px;
}

@media screen and (max-width: 1200px) {
  #menucontainer {
    display: block;
  }

  #menubutton {
    display: block;
  }
}
main {
  /*padding-top: 80px;*/
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 120px;
}

#inner_main {
  max-width: 1000px;
}

body {
  background-color: white !important;
}

@media screen and (max-width: 1200px) {
  main {
    margin: 120px 100px;
  }
}
@media screen and (max-width: 1000px) {
  main {
    margin: 120px 50px;
  }
}
@media screen and (max-width: 500px) {
  main {
    margin: 120px 20px;
  }
}
header {
  /*border-bottom: 1px solid black;*/
  position: fixed;
  height: 120px;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 99;
}
header #logo {
  height: 55px;
  width: 50px;
  position: absolute;
  top: 79px;
  transform: translateY(-50%);
  margin-left: 30px;
  z-index: 200;
}
header #logo img {
  height: 100%;
}
header nav {
  margin: 85px auto 25px auto;
  position: relative;
  top: 0px;
  max-width: 1000px;
  /*margin-left: 100px;*/
  /*margin-top: 30px;*/
}
header nav a {
  color: black;
  text-decoration: none;
  font-family: "Noto Sans", sans-serif;
  font-weight: 700;
  margin: 20px 2% 20px 1%;
  cursor: pointer;
}
header nav a:nth-child(8) {
  margin-right: 0;
}
header nav a:hover {
  color: #dd2a57;
}
header .selected {
  color: #dd2a57;
}
header .last {
  margin-right: 0;
}
header .toprightbtns {
  margin: 0px auto 25px;
  max-width: 1000px;
  text-align: right;
  position: absolute;
  width: 100%;
  top: -50px;
}
header .toprightbtns a {
  font-weight: 400;
  margin-right: 1%;
}
header .toprightbtns #submitSearch {
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;
}
header .toprightbtns #submitSearch i {
  font-size: 20px;
}
header a {
  text-decoration: none;
}

#admin {
  top: 0;
  right: 0;
  position: absolute;
}

.nav_size0 a {
  margin-right: 4.5%;
}

.nav_size1 a {
  margin-right: 3.2%;
}

.nav_size2 a {
  margin-right: 1.8%;
}

.nav_size3 a {
  margin-right: 0.3%;
}

#searchinput {
  border: none;
  border-bottom: 2px solid #000000;
}

.displayBlock {
  display: block;
}

@media screen and (max-width: 1200px) {
  header nav {
    margin: 85px 100px 0;
    display: block;
  }
  header .toprightbtns {
    display: block;
  }
}
@media screen and (max-width: 1200px) {
  header nav {
    display: none;
  }

  header .toprightbtns {
    display: none;
    position: relative;
    text-align: unset;
    top: unset;
    margin: 0px auto;
    max-width: unset;
  }
  header .toprightbtns a {
    clear: both;
    display: block;
    width: 100%;
    margin: 0px -5px;
    padding: 5px;
    font-weight: 700;
  }
  header .toprightbtns form {
    margin: 0px auto;
    width: 80%;
  }
  header .toprightbtns form input {
    height: 40px;
    margin-bottom: 15px;
  }
  header .toprightbtns form #searchinput {
    width: 80%;
    float: left;
    margin-left: -5px;
    padding-left: 5px;
  }
  header .toprightbtns form #submitSearch {
    width: 18%;
    height: 42px;
    float: right;
    margin-right: -5px;
    padding-right: 5px;
  }
  header .toprightbtns form #submitSearch i {
    font-size: 25px;
  }

  .open_nav {
    position: absolute;
    top: 0;
    left: 0;
    height: 150vh;
    width: 100vw;
    background-color: white;
    z-index: 100;
    margin: 0;
    max-width: inherit;
    padding-top: 110px;
    background-color: #BEBEBE;
  }
  .open_nav .nav_btn {
    display: block;
    margin: 20px auto;
    width: 80%;
    padding: 5px;
    background-color: #FFFFFF;
  }
  .open_nav .nav_btn:hover, .open_nav .selected {
    color: #FFFFFF;
    background-color: #dd2a57;
  }
}
@media screen and (max-width: 1200px) {
  header #logo {
    margin-left: calc(100px + 1%);
  }
  header #menubutton {
    margin-right: calc(100px + 1%);
  }
}
@media screen and (max-width: 1000px) {
  header #logo {
    margin-left: calc(50px + 1%);
  }
  header #menubutton {
    margin-right: calc(50px + 1%);
  }
}
@media screen and (max-width: 800px) {
  header #logo {
    margin-left: calc(50px + 1%);
  }
  header #menubutton {
    margin-right: calc(50px + 1%);
  }
}
@media screen and (max-width: 500px) {
  header #logo {
    margin-left: calc(25px + 1%);
  }
  header #menubutton {
    margin-right: calc(25px + 1%);
  }
}
footer {
  position: relative;
  max-width: 1000px;
  margin: 50px auto 50px;
  font-family: "Noto Sans", sans-serif;
}
footer #logoFooterBig {
  max-width: 400px;
  padding-left: 1%;
  padding-bottom: 50px;
  position: relative;
}

@media screen and (max-width: 1200px) {
  footer {
    margin: 0 50px 50px;
  }
}
@media screen and (max-width: 500px) {
  footer {
    margin: 0 20px 50px;
  }
}
/*
@import 'base/module';
@import 'components/module';
@import 'layout/module';
@import 'pages/module';
@import 'themes/module';
@import 'vendors/module';



sass/
|
|– base/
|   |– _reset.scss        # Reset/normalize
|   |– _typography.scss   # Typography rules
|   |– _variables.scss    # Sass Variables
|   |– _functions.scss    # Sass Functions
|   |– _mixins.scss       # Sass Mixins
|   |– _placeholders.scss # Sass Placeholders
|   …                     # Etc.
|
|– components/
|   |– _buttons.scss      # Buttons
|   |– _carousel.scss     # Carousel
|   |– _cover.scss        # Cover
|   |– _dropdown.scss     # Dropdown
|   …                     # Etc.
|
|– layout/
|   |– _navigation.scss   # Navigation
|   |– _grid.scss         # Grid system
|   |– _header.scss       # Header
|   |– _footer.scss       # Footer
|   |– _sidebar.scss      # Sidebar
|   |– _forms.scss        # Forms
|   …                     # Etc.
|
|– pages/
|   |– _home.scss         # Home specific styles
|   |– _contact.scss      # Contact specific styles
|   …                     # Etc.
|
|– themes/
|   |– _theme.scss        # Default theme
|   |– _admin.scss        # Admin theme
|   …                     # Etc.
|
|– vendors/
|   |– _bootstrap.scss    # Bootstrap
|   |– _jquery-ui.scss    # jQuery UI
|   …                     # Etc.
|
`– main.scss              # Main Sass file

*/