footer {
    position: relative;
    max-width: 1000px;
    margin: 50px auto 50px;
    font-family: 'Noto Sans', sans-serif;
    
    #logoFooterBig {
        max-width: 400px;
        padding-left: 1%;
        padding-bottom: 50px;
        position: relative;
    }
}

@media screen and (max-width: 1200px) {
    footer {
        margin: 0 50px 50px;

    }
}

@media screen and (max-width: 500px) {
     footer {
        margin: 0 20px 50px;

    }   
}