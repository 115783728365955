main {
    /*padding-top: 80px;*/
    max-width: 1000px;
    margin: 0 auto;
    margin-top: 120px;
}
#inner_main {
    max-width: 1000px;
}
body {
    background-color: white !important;
}

@media screen and (max-width: 1200px) {
    main {
        margin: 120px 100px;
    }
}
@media screen and (max-width: 1000px) {
    main {
        margin: 120px 50px;
    }
}
@media screen and (max-width: 500px) {

    main {
        margin: 120px 20px;
    }
}

