#menucontainer {
    display: none;
    max-width: 1000px;
    margin: 0px 100px;
    height: 40px;
}
#menubutton {
    display: none;
    position: absolute;
    //left:48.5%;
    right:0%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 999;
    width:40px;
    height:40px;
    top:86px;
    background-color: none;
    margin-right: 110px;
}


#menubutton span {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: transform .25s ease-in-out, top .25s ease-in-out;
    display: block;
    position:absolute;
    height: 7px;
    width: 40px;
    background: black;
    opacity: 1;
}
#menubutton.open span:nth-child(1),#menubutton.open span:nth-child(4) {
    width: 0px;
    top: 18px;
}
#menubutton.open span:nth-child(2)  {
    transform: rotate(45deg);
    width: 50px;
    left: -5px;
    

}
#menubutton.open span:nth-child(3) {
    transform: rotate(-45deg);
    width: 50px;
    left: -5px;

}
#menubutton span:nth-child(1) {
    top: 10px;
}
#menubutton span:nth-child(2),#menubutton span:nth-child(3) {
    top: 22px;
    
}
#menubutton span:nth-child(4) {
    top: 34px;
}

@media screen and (max-width: 1200px) {
    #menucontainer {
        display: block;
    }
    #menubutton {
        display: block;
    }
}