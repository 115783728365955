header {
    /*border-bottom: 1px solid black;*/
    position: fixed;
    height: 120px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 99;
    #logo {
        height: 55px;
        width: 50px;
        position: absolute;
        top: 79px;
        transform: translateY(-50%);
        margin-left: 30px;
        z-index: 200;
        img {
            height: 100%;
        }
    }

    nav {
        margin: 85px auto 25px auto;
        position: relative;
        top: 0px;
        max-width: 1000px;
        /*margin-left: 100px;*/
        /*margin-top: 30px;*/
    }
    nav a {
        color: black;
        text-decoration: none;
        font-family: 'Noto Sans', sans-serif;
        font-weight: 700;
        margin: 20px 2% 20px 1%;
        cursor: pointer;
    }
    nav a:nth-child(8){
        margin-right: 0;
    }
    nav a:hover{
        color: #dd2a57;
    }
    .selected {
        color: #dd2a57;
    }
    .last {
        margin-right: 0;
    }
    .toprightbtns{
        margin: 0px auto 25px;
        max-width: 1000px;
        text-align: right;
        position: absolute;
        width: 100%;
        top: -50px;
        a {
            font-weight: 400;
            margin-right: 1%;
        }
        #submitSearch {
            width: 30px;
            height: 30px;
            background:none;
            border: none;
            cursor: pointer;
            i {
                font-size: 20px;
            }
        }
    }
    a {
        text-decoration: none;
    }
}

    #admin {
        top:0;
        right: 0;
        position: absolute;
    }
    .nav_size0 {
        a{
            margin-right: 4.5%;
        }
    }
    .nav_size1 {
        a{
            margin-right: 3.2%;
        }
    }
    .nav_size2 {
        a{
            margin-right: 1.8%;
        }
    }
    .nav_size3 {
        a{
            margin-right: 0.3%;
        }
    }
    #searchinput {
        border: none;
        border-bottom: 2px solid #000000;
    }
    .displayBlock {
        display: block;
    }
    
@media screen and (max-width: 1200px) {
    header {
        nav {
            margin: 85px 100px 0;
            display: block;
        }
        .toprightbtns {
            display: block;
        }
    }
}
@media screen and (max-width: 1200px) {
    header nav {
        display: none;
    }
    
    header .toprightbtns {
        display: none;
        position: relative;
        text-align: unset;
        top: unset;
        margin: 0px auto;
        max-width: unset;
        a {
            clear: both;
            display: block;
            width: 100%;
            margin: 0px -5px;
            padding: 5px;
            font-weight: 700;
        }
        form {
            margin: 0px auto;
            width: 80%;
            input {
                height: 40px;
                margin-bottom: 15px;
            }
            #searchinput {
                width: 80%;
                float: left;
                margin-left: -5px;
                padding-left: 5px;
            }
            #submitSearch {
                width: 18%;
                height: 42px;
                float: right;
                margin-right: -5px;
                padding-right: 5px;
                i {
                    font-size: 25px;
                }
            }
        }
    }
    .open_nav {
        position: absolute;
        top: 0;
        left: 0;
        height: 150vh;
        width: 100vw;
        background-color: white;
        z-index: 100;
        margin: 0;
        max-width: inherit;
        padding-top: 110px;
        background-color: #BEBEBE;
        .nav_btn {
            display: block;
            margin: 20px auto;
            width: 80%;
            //text-align: center;
            padding: 5px;
            background-color: #FFFFFF;
        }
        .nav_btn:hover, .selected {
            color: #FFFFFF;
            background-color: #dd2a57;
        }
    }
}
@media screen and (max-width: 1200px) {
    header{
        #logo {
            margin-left: calc(100px + 1%);
        }
        #menubutton {
            margin-right: calc(100px + 1%);
        }
    }
}
@media screen and (max-width: 1000px) {
    header{
        #logo {
            margin-left: calc(50px + 1%);
        }
        #menubutton {
            margin-right: calc(50px + 1%);
        }
    }
}
@media screen and (max-width: 800px) {
    header{
        #logo {
            margin-left: calc(50px + 1%);
        }
        #menubutton {
            margin-right: calc(50px + 1%);
        }
    }
}
@media screen and (max-width: 500px) {
    header{
        #logo {
            margin-left: calc(25px + 1%);
        }
        #menubutton {
            margin-right: calc(25px + 1%);
        }
    }
}