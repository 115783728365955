.article {
    clear:both;
    padding: 0px 1%;
    overflow: auto;
    a {
        text-decoration: underline;
    }
    p {
        margin-bottom: 10px;
        //margin-bottom: 40px;
    }
    h4 {
        margin-bottom: 5px;
        font-weight: normal;
        font-size: 20px;
    }
}
.subtitle {
    font-weight: bold;
}
.clear {
    clear: both;
}
.news {
    width: 98%;
    padding: 20px 1%;
    max-height: 290px;
}
.description {
    padding: 20px 1% 
}
.img_preview {
    margin: 5px 0px;
    float: left;
    width: 30%;
    height: 180px;
    text-align: center;
}
.img_preview img {
    height: 100%;
    width: 97.5%;
    object-fit: cover;
    margin-right: 2.5%;
}
.art_preview1 {
    padding-left: 32%;
    min-height: 185px;
}
.art_preview2 {
    //background-color: aquamarine;
}

.kachel {
    height: 300px;
    width: 48.5%;
    float: left;
    padding: 20px 0.5% 0 1%;
    .image_alt, .kachelimg{
        width: 100%;
        height: 85%;
        background-color: #BEBEBE;
        cursor: pointer;
        h4 {
            padding: 10px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .displaynone {
        display: none;
    }
    .kacheltitel {
        text-align: center;
        height: 15%;
        color: #FFFFFF;
        font-size: 25px;
        background-color: #dd2a57;
        padding-top: 5px;
        font-weight: 600;
    }
    a {
        margin-left: 10px;
        padding: 0 5px;
        background-color: #FFFFFF;
        text-decoration: none;
        font-weight: bold;
    }
}
.kachel:hover {
    .image_alt {
        display: block;
    };
    .kachelimg {
        display: none;
    };
}
.kachelClear {
    clear: both;
    padding-bottom: 20px;
}

.gallery {
    position: relative;
    height: 400px;
    -webkit-transition: left .8s ease-in-out;
    -moz-transition: left .8s ease-in-out;
    -o-transition: left .8s ease-in-out;
    transition: left .8s ease-in-out;
    img {
        vertical-align: middle;
        padding-right: 15px;
        height: 85%;
        width: 100%;
        object-fit: cover;
        transition: all .6s;
    }
    .slides {
        display: inline-flex;
        height: 400px;
        .insideSlide {
            overflow: hidden;
            height: 100%;
            width: 97%;
        }
    }
    .left {
        position: fixed; 
        left: 0;
    }
}
.textContainer {
    height: 15%;
    overflow: hidden;
    transition: all .6s;
}
.gallery .insideSlide:hover {
    img {
        opacity: 0.3;
    }
    .textContainer {
        margin: -335px 5px 0 ;
        position: relative;
        height: auto;
    }
}
.biggallerybox, .gallerybox {
    .arrow {
        border: solid black;
        border-width: 0 3.5px 3.5px 0;
        display: inline-block;
        padding: 3px;
        height: 12px;
        width: 12px;
    }
    .right {
        transform: rotate(-45deg);
    }
    .left {
        transform: rotate(135deg);
    }
}
.gallerybox {
    padding: 20px 0%;
    margin: 0px 1%;
    overflow-x: hidden;
    position: relative;
    width: 99.5%;
    height: 400px;
    button {
        cursor: pointer;
        position: absolute;
        top: calc(85% / 2);
        padding: 16px;
        margin-top: -22px;
        color: #000000;
        font-size: 18px;
        border: 0;
        background-color: #FFFFFF;
        opacity: 0.8;
    }
    .btn_right {
        padding: 12px 16px 12px 8px;
        right: 30px;
    }
    .btn_left {
        padding: 12px 8px 12px 16px;
        left: 15px;
    }
}
.biggallerybox {
    padding: 0 1%;
    .gallery_container {
        height: 600px;
        background-color: rgba(200,200,200,0.5);
        .preview_container {
            height: 100%;
            position: relative;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
    }
    .gallery_next, .gallery_previous {
        position: absolute;
        top: 50%;
        display: block;
        height: 50px;
        width: 50px;
        transform: translateY(-50%);
        cursor: pointer;
        padding: 16px;
        color: #000000;
        font-size: 18px;
        border: 0;
        background-color: #FFFFFF;
        opacity: 0.8;
    }
    .gallery_next {
        padding: 12px 16px 8px 8px;
        right: 15px;
    }
    .gallery_previous {
        padding: 12px 8px 8px 16px;
        left: 15px;
    }
}
.portrait {
    height: 300px;
    width: 31.3%;
    margin: 0.5%;
    float: left;
    padding: 10px 0.5%;
    cursor: pointer;
    img {
        height: 75%;
        width: 100%;
        object-fit: cover;
    }
}
.portrait_container {
    width: 98%;
    padding: 20px 1%;
    height: 600px;
    .portrait_big {
        height: 560px;
        max-height: 560px;
        width: 98%;
        padding: 20px 1%;
        background-size: cover;
        background-position: center;
        img {
            //height: 100%;
            width: 98%;
            object-fit: cover;
        }
        .infoField {
            top: -350px;
        }
        #speechbubble {
            width: 90%;
            margin: 0px auto;
            position: unset;
            transform: unset;
        }
        .triangle_btm_left{
            border-bottom: 50px solid #dd2a57;
            border-right: 50px solid transparent;
            height: 0;
            margin-left: 10%;
            margin-top: 250px;
            position: unset;
        }
    }
}
.slideshow-container {
    width: 98%;
    height: 600px;
    padding: 20px 1%;
    position: relative;
    .slideshow-slide{
        height: 100%;
        img{
            height: 100%;
            max-height: 600px;
            width: 100%;
            object-fit: cover;
        }
    }
    .info_btn {
        position: absolute;
        z-index: 10;
        bottom: 100px;
        left: -50px;
        height: 80px;
        img {
            height: 100%;
        }
    }
}
.slide-fade-in{
    animation-name: fadeInAndOut;
    animation-duration: 5s;
    transition-timing-function: linear;
}
@keyframes fadeInAndOut {
  0%,100% {
    opacity: 0;
  }
  20%,80% {
    opacity: 1;
  }
}
h2 {
    //padding-bottom: 10px;
    font-family: 'Noto Serif', serif;
    font-weight: 700;
    font-size: 30px;
    margin: 15px 0px 15px;
    
}

h3 {
    font-family: 'Noto Serif', serif;
    font-weight: 700;
    font-size: 25px;
    margin: 15px 0px 15px;
}

strong {
    font-family: 'Noto Serif', serif;
    font-weight: 700;
}

.download {
    display: block;
    font-family: 'Noto Serif', serif;
    text-decoration: underline;
    cursor: pointer;
}
.linkItalic {
    font-family: 'Noto Serif', serif;
    text-decoration: underline;
    font-style: italic;
    cursor: pointer;
}

a {
    color: black;
    &:hover {
        color: #dd2a57;
    }
}

.edit {
    position: relative;
}

.autoHeight {
    max-height: none;
    height: auto;
}
.comment {
    font-weight: normal;
    font-style: italic;
}
h1 {
    padding-left: 1%;
    padding-bottom: 15px;
}
.linklist p{
    font-weight: bold;
    padding-bottom: 20px;
}
.video {
    max-height: 500px;
    height: 50vw;
    width: 100%;
    margin: 20px 0; 
}
.banner {
    text-align: center;
    padding: 20px 0px;
    height: 560px;
    img{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}
.title_images {
    position: relative;
    height: 340px;
    margin-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    img {
        padding-right: 15px;
        height: 80%;
        width: 100%;
        object-fit: cover;
    }
    .slides {
        width: 100%;
        height: 100%;
        display: inline-block;
        .insideSlide {
            height: 100%;
            width: 97%;
        }
    }
    .halfslides {
        height: 100%;
        width: 50%;
        img {
            height: 100%;
        }
    }
}
#modal {
    height: 100%;
    max-width: 1000px;
    width: 100%;
    margin: 0px auto;
    display: none;
    position: fixed;
    top: 100px;
    background-color: #FFFFFF;
    //padding: 0px 1%;
    word-wrap: break-word;
    #modalimg {
        width: 100%;
    }
    .modal-content {
        margin: 50px 1%;
        animation-name: zoom;
        animation-duration: 0.6s;
        width: 98%;
        height: 600px;
        background-size: cover;
        background-position: center;
    }
    @keyframes zoom {
        from {transform: scale(0.1)} 
        to {transform: scale(1)}
    }
}
.close {
    position: relative;
    margin-left: 95%;
    color: #f1f1f1;
    font-size: 50px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}
.triangle_btm_left {
    position: absolute;
    left: 15%;
    top: 380px;
    width: 0;
    height: 0;
    border-bottom: 85px solid #dd2a57;
    border-right: 85px solid transparent;
}
#speechbubble {
    word-wrap: break-word;
    color: white;
    width: 85%;
    background-color: #dd2a57;
    position: absolute;
    top: 500px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 10px;
    #textspeech {
        font-weight: bold;
        font-size: 25px;
    }
    #authorspeech{
        margin-top: 10px;
        font-style: italic;
    }
}
.infoField {
    position: relative;
    top: -250px;
    #speechbubble {
        margin: 0 auto;
        position: unset;
        transform: none;
    }
    .triangle_btm_left {
        position: unset;
        margin-left: 125px;
    }
}
.resultValue {
    max-height: 200px;
    overflow: hidden;
    -webkit-mask-image: linear-gradient(180deg, #000 60%, transparent);
}
.result {
    border: solid 1.5px #FFFFFF;
    padding: 10px 1%;
}
.result:hover {
    border: solid 1.5px #000000; 
    transition: border 1s;
}
.noContent {
    padding: 20px 1%;
}
li {
    padding: 5px 0px;
    margin-left: 20px;
}
.articleText {
    //max-height: 170px;
    overflow: hidden;
}
.btnContainer {
    padding: 10px 0px;
}
.openArticle {
    max-height: unset;
    overflow: unset;
}
#docDisplay {
    width: 98%;
    height: 700px;
    max-height: calc(90vh - 120px);
    padding: 15px 1% 10px;
}
#loginInput {
    padding-top: 270px;
    width: 100%;
    min-width: 350px;
    max-width: 600px;
    margin: 0px auto;
    width: 94%;
}
#pwInput {
    font-size: 18px;
    border: none;
    padding: 0px 10px;
    width: calc(70% - 20px);
    height: 50px;
    margin-right: 2%
}
#loginBtn {
    font-size: 18px;
    width: 28%;
    height: 50px;
    border: none;
    background-color: #dd2a57;
    color: #FFFFFF;
    cursor: pointer;
    font-weight: bold; 
}
.form {
    padding: 20px 0px;
}
.formContainer {
    height: 600px;
    background-color: #bebebe;
    width: 98%;
    margin: 0px 1%;
}
.network {
    padding: 10px 0px;
    .networktext {
        margin-top: 10px;
    }
}
.toggleContainer {
    cursor: pointer;
    padding: 0px 10px;
    background-color: #bebebe;
    p {
        margin: 0;
        padding: 5px 0px;
    }
}
.toggleContainer:hover {
    background-color: #dd2a57;
    color: #FFFFFF;
    transition: all .6s ease;
}
.displayNone {
    display: none;
}
.first-title {
    padding-top: 15px;
}
@media screen and (max-width: 1200px) {
    #modal {
        margin-right: 100px;
        width: calc(100% - 200px);
        .modal-content{
            width: 98%;
            height: 50vw;
            .triangle_btm_left {
                //margin: calc(50vw + 2%) 0px 0px 5%;
                left: 10%;
                top: calc(50vw + 7%);
            }
            #speechbubble {
                margin-top: calc(50vw + 10% + 100px);
                top: 20px;
            }
        }
    }
}
@media screen and (max-width: 1000px) {
    .news {
        height: auto;
        max-height: unset;
        .img_preview {
            float: none;
            width: 100%;
            img {
                width: 100%;
            }
        }
        .art_preview1 {
            padding-left: unset;
            min-height: unset;
        }
    }
    .portrait_container {
        height: auto;
        margin-bottom: -100px;
        .portrait_big {
            height: 360px;
        }
        .infoField {
            /*
            position: absolute;
            top: calc(50vw + 30px);
            max-width: calc(100% - 50px);
            */
            margin: 0 20px;
            top: -100px;
            .triangle_btm_left {
                margin-left: 10vw;
                border-bottom: 50px solid #dd2a57;
                border-right: 50px solid transparent;
            }
        }
    }
    .banner {
        height: 360px;
    }
    #modal {
        width: calc(100% - 100px);
        .infoField {
            top: -30vw;
            margin: 0 20px;
            .triangle_btm_left {
                margin-left: 10vw;
                border-bottom: 50px solid #dd2a57;
                border-right: 50px solid transparent;
            }
        }
    }
    .slideshow-container {
        height: 400px;
    }
    .gallerybox {
        height: 340px;
        .gallery {
            height: 340px;
        }
        .slides {
            height: 340px;
        }
        img {
            height: 100%;
        }
        .btn_right, .btn_left {
            top: 48.5%;
        }
    }
    .textContainer {
        display: none;
    }
}
@media screen and (max-width: 800px) {
    .kachel{
        width: 98%;
        padding-bottom: 15px;
    }
    .kachelClear {
        padding-bottom: 0px;
    }
    .portrait{
        width: 48%;
    }
    .gallerybox .button{
        top: 50%;
    }
    .gallery img{
        height: 100%;
    }
    .comment {
        display: none;
    }
    .title_images {
        .slides {
            width: 100%;
        }
    }
    #halfslide2 {
        display: none;
    }
    .close {
        margin-left: 92%;
    }
    .banner {
        height: 280px;
    }
}
@media screen and (max-width: 500px) {
    .portrait{
        height: auto;
        width: 97%;
        padding-bottom: 20px;
    }
    #modal {
        width: calc(100% - 40px);
        .modal-content {
            margin: 50px 2%;
            width: 96%;
        }
    }
    #loginInput {
        min-width: unset;
        padding-top: 140.5px;
    }
    .formContainer {
        height: 400px;
        width: 96%;
        margin: 0px 2%;
    }
    #pwInput, #loginBtn {
        display: block;
        width: 98%;
        margin: 0px 1% 25px;
    }
    #pwInput {
        width: calc(98% - 20px);
    }
    .close {
        margin-left: 87%;
    }
    #speechbubble #textspeech {
        font-size: 22px;
    }
    .banner {
        height: 220px;
    }
}